<template>
  <div>
    <div class="text-accent  relative">
      <Navbar v-if="!isApp" />

      <slot />
    </div>
  </div>
  <Toaster />
</template>

<script type="ts" setup>
import Navbar from '~/components/landing/navbar.vue'
import { Toaster } from '~/components/toast'

const isApp = useIsApp();

await useLoggedInChecker()
</script>
